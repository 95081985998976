
import { Component, Vue } from "vue-property-decorator";
import Navigation from "@/components/navigation/Navigation.vue";
import SkipToButton from "@/components/SkipToButton.vue";

@Component({
  components: {
    Navigation,
    SkipToButton
  }
})
export default class Home extends Vue {
  private logoImg = require("../assets/leighton.30.png");
  private menuItems = [
    {
      name: "About",
      url: "/about"
    },
    {
      name: "Services",
      url: "/services",
      subMenu: [
        {
          name: "Our Services",
          url: "/services"
        },
        {
          name: "Software Development",
          url: "/services/software-development"
        },
        {
          name: "Team Augmentation",
          url: "/services/team-augmentation"
        }
      ]
    },
    {
      name: "Products",
      url: "/products",
      linkType: "internal",
      subMenu: [
        {
          name: "Products by Leighton",
          url: "/products"
        },
        {
          name: "Human by Leighton",
          url: "https://wearehuman.hr/",
          external: true
        },
        {
          name: "Academy by Leighton",
          url: "https://leighton.academy/",
          external: true
        }
      ]
    },
    {
      name: "Resources",
      url: "/resources"
    },
    {
      name: "Join Us",
      subMenu: [
        {
          name: "Working at Leighton",
          url: "/join-us/working-at-leighton"
        },
        {
          name: "Current Vacancies",
          url: "/join-us/current-vacancies#vacancies"
        }
      ]
    },
    {
      name: "Get In Touch",
      url: "/get-in-touch"
    }
  ];

  private buttonData1 = {
    buttonId: "skiptomaincontent",
    buttonClass: "visually-hidden",
    link: "#skiptonav",
    text: "Skip to content"
  };

  private buttonData2 = {
    buttonId: "skiptonav",
    buttonClass: "visually-hidden",
    link: "#skiptomaincontent",
    text: "Skip to navigation"
  };
}
