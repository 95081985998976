
import { Component, Vue } from "vue-property-decorator";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";

@Component({
  components: {
    MainHeader,
    MainFooter
  }
})
export default class LayoutDefault extends Vue {}
