
import { Component, Vue } from "vue-property-decorator";
import MainLogo from "@/components/MainLogo.vue";
import PageBreak from "@/components/PageBreak.vue";
import TitleText from "@/components/TitleText.vue";
import Form from "@/components/Form.vue";
import { NewsletterForm as INewsletterForm } from "@/interfaces/NewsletterForm.interface";
import ApiService from "@/services/ApiService";

@Component({
  components: {
    MainLogo,
    PageBreak,
    TitleText,
    Form
  }
})
export default class MainFooter extends Vue {
  private logoImg = require("../assets/leighton-dark.png");
  private greatPlacesImg = require("../assets/great-places-to-work.png");

  private socialItems = [
    {
      itemName: "facebook",
      itemTitle: "Find us on Facebook",
      itemURL: "https://www.facebook.com/LeightonCorpLtd"
    },
    {
      itemName: "twitter",
      itemTitle: "Follow us on Twitter",
      itemURL: "https://twitter.com/Leighton"
    },
    {
      itemName: "linkedin",
      itemTitle: "Connect with us on Linkedin",
      itemURL: "https://www.linkedin.com/company/leighton"
    }
  ];

  private pageBreak1CustomClass = "footer-break mt-4";
  private pageBreak2CustomClass = "footer-break mt-5 col-12 d-none";
  private pageBreak3CustomClass = "footer-break my-2 mt-lg-5";

  private titleText1 = {
    title: "Receive the Leighton Newsletter",
    text:
      "Sign up to hear news, customer stories and events and our views on the technologies that are inspiring us!",
    customClass: "titletext--footer-newsletter text-left mt-5 mt-lg-4"
  };

  private form = {
    success: false,
    error: false,
    popUpMsg: ``,
    customClass: "footer-form mt-4",
    buttonText: "Sign up",
    formData: {
      fullName: {
        value: "",
        errMsg: "Please enter your full name",
        error: false
      },
      company: {
        value: "",
        errMsg: "Please enter your company name",
        error: false
      },
      email: {
        value: "",
        errMsg: "Please enter a valid email",
        error: false
      }
    },
    rows: [
      [
        {
          label: "Full Name",
          name: "fullName",
          type: "text",
          model: "fullName",
          required: true
        }
      ], // row 1
      [
        {
          label: "Company",
          name: "company",
          type: "text",
          model: "company",
          required: true
        }
      ], // row 2
      [
        {
          label: "Email Address",
          name: "email",
          type: "text",
          model: "email",
          required: true
        }
      ] // row 3
    ]
  };

  private regex = /(<([^>]+)>)/gi;

  private async handleForm(formData: INewsletterForm) {
    formData.fullName.value = formData.fullName.value.replace(this.regex, "");
    formData.company.value = formData.company.value.replace(this.regex, "");
    formData.email.value = formData.email.value.replace(this.regex, "");

    // reset each error so that if its the users second attempt, the error does not stay visible upon success
    this.form.formData.fullName.error = false;
    this.form.formData.company.error = false;
    this.form.formData.email.error = false;

    await ApiService.post(
      "https://0spz8bbss8.execute-api.eu-west-2.amazonaws.com/dev/submitted",
      formData
    )
      .then(resp => {
        if (resp.has_error) {
          if (resp.messages.fullName.status === true) {
            this.form.formData.fullName.error = true;
          } else {
            this.form.formData.fullName.error = false;
          }
          if (resp.messages.company.status === true) {
            this.form.formData.company.error = true;
          } else {
            this.form.formData.company.error = false;
          }
          if (resp.messages.email.status === true) {
            this.form.formData.email.error = true;
          } else {
            this.form.formData.email.error = false;
          }
        } else {
          this.form.error = false;
          this.form.success = true;
          this.form.popUpMsg = `
            <p>
              Thank you for signing up to our newsletter.
            </p>
          `;
        }
      })
      .catch(() => {
        this.form.error = true;
        this.form.success = false;
        this.form.popUpMsg = `
            <p>
              There was a problem submitting the form.
            </p>
          `;
      });
  }

  private phone = {
    content: "+44 (0)191 305 5140",
    link: "tel:+441913055140"
  };

  private partners = {
    iso: {
      img: require("../assets/footer-partners/partners-iso.png"),
      alt: "ISO/IEC 27001 - Information Security Management",
      text: "IS 622390"
    },
    aws: {
      img: require("../assets/footer-partners/partners-aws.png"),
      alt: "Amazon Web Services"
    },
    crownCommercial: {
      img: require("../assets/footer-partners/partners-ccs.png"),
      alt: "Crown Commercial Service Supplier"
    }
  };

  private date = new Date();

  private closePopUp(): void {
    // if error message is shown, dont reset form when message is closed
    if (this.form.error) {
      this.form.error = false;
    } else {
      // if success message is shown reset the form when message is closed
      this.form.formData.fullName.value = "";
      this.form.formData.company.value = "";
      this.form.formData.email.value = "";
      this.form.success = false;
    }
  }
}
