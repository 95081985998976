
import { Component, Prop, Vue } from "vue-property-decorator";

interface Decorator {
  type: string;
  image: string;
  top: string;
  right: string;
  bottom: string;
  left: string;
  width: string;
  height: string;
  behind: boolean;
}

@Component
export default class TitleText extends Vue {
  @Prop() private titleTextImage!: string;
  @Prop() private titleTextImageAlt!: string;
  @Prop() private titleTextHeading!: string;
  @Prop() private titleTextTitle!: string;
  @Prop() private titleTextSubTitle!: string;
  @Prop() private titleTextButtonText!: string;
  @Prop() private titleTextButtonLink!: string;
  @Prop() private titleTextButtonLinkExternal!: boolean;
  @Prop() private buttonLabel!: string;
  @Prop() private titleTextCustomClass!: string;
  @Prop() private titleTextDecorators!: Decorator[];
  @Prop({ default: "h2" }) private titleTextHeadingTag!: string;

  private decorStyle(index: number): string {
    let style = "";

    style += this.titleTextDecorators[index].top
      ? `top:${this.titleTextDecorators[index].top};`
      : "";
    style += this.titleTextDecorators[index].right
      ? `right:${this.titleTextDecorators[index].right};`
      : "";
    style += this.titleTextDecorators[index].bottom
      ? `bottom:${this.titleTextDecorators[index].bottom};`
      : "";
    style += this.titleTextDecorators[index].left
      ? `left:${this.titleTextDecorators[index].left};`
      : "";
    style += this.titleTextDecorators[index].width
      ? `width:${this.titleTextDecorators[index].width};`
      : "";
    style += this.titleTextDecorators[index].height
      ? `height:${this.titleTextDecorators[index].height};`
      : "";

    return style;
  }

  get headingType() {
    return this.titleTextHeadingTag;
  }
}
