
import { Component, Prop, Vue } from "vue-property-decorator";
import { JoinUsForm as IJoinUsForm } from "@/interfaces/JoinUsForm.interface";

@Component
export default class ContactForm extends Vue {
  @Prop() private rows!: []; // Row structure passsed in from parent component
  @Prop() private formData!: IJoinUsForm; // Form fields passed in from parent component
  @Prop() private formHeading!: string;
  @Prop() private formSubHeading?: string;
  @Prop({ default: "Submit form" }) private buttonText!: string;
  @Prop() private customClass!: string;
  @Prop() private gdpr!: string;
  @Prop() private success!: boolean;
  @Prop() private error!: boolean;
  @Prop() private popUpMsg!: string;

  private formPopUpOpen = false;
  private highlightDropzone = false;
  private fileUploaded = false;
  private isInitial = true;
  private isSaving = false;
  private progress = 0;
  private removeFileIcon = require("@/assets/green-x.png");

  private uploadedFiles: File[] = [];
  private uploadError = false;
  private uploadErrorMessage = "Error uploading files";

  private addFile(fileList: FileList): void {
    this.isInitial = false;
    this.isSaving = true;
    const fileArray = Array.from(fileList);
    for (let i = 0; i < fileArray.length; i++) {
      this.formData.fileNames.push(fileArray[i].name);
    }
    this.formData.fileUpload.value = fileList;
    this.fileUploaded = true;
    this.isSaving = false;
    this.isInitial = true;
  }
  private dragEnter(): void {
    this.highlightDropzone = true;
  }

  private dragLeave(): void {
    this.highlightDropzone = false;
  }

  public handleForm(e: Event) {
    e.preventDefault();
    this.$emit("formSubmit", this.formData);
  }

  private removeFile() {
    this.formData.fileNames = [];
    this.$refs.fileInput = [];
  }

  private closePopUp(): void {
    this.$emit("closePopUp");
  }

  private dragOn(): void {
    this.highlightDropzone = true;
  }

  private dragOff(): void {
    this.highlightDropzone = false;
  }

  private calcColumns(rowLength: number) {
    const desktopClass = `col-md-${
      rowLength <= 12 ? Math.floor(12 / rowLength) : 12
    }`;
    return `${desktopClass}`;
  }
}
