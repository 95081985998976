
import { Component, Prop, Vue } from "vue-property-decorator";
import MainLogo from "@/components/MainLogo.vue";
import MenuItem from "@/components/navigation/NavItem.vue";

@Component({
  components: {
    MainLogo,
    MenuItem
  }
})
export default class Navigation extends Vue {
  @Prop() private menuItems!: [];
  @Prop() private logoImg!: string;
  @Prop() private subMenuActive!: boolean;
  @Prop() private activeMenuItem!: number | null;

  private mobileMenuActive = false;

  toggleMobileMenu(): void {
    this.mobileMenuActive = !this.mobileMenuActive;
  }
}
