
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class NavItem extends Vue {
  @Prop() private menuItem!: object;
  @Prop() private index!: number;

  dropdownOpen(): void {
    if (window.innerWidth >= 992) {
      const navLinkID = document.getElementById(`nav-item${this.index}`);
      const navButtonID = document.getElementById(
        `nav-item${this.index}__BV_toggle_`
      );

      navLinkID?.classList.add("show");
      navButtonID?.setAttribute("aria-expanded", "true");
      navLinkID
        ?.getElementsByClassName("dropdown-menu")[0]
        .classList.add("show");
    } else {
      return;
    }
  }

  dropdownClose(): void {
    if (window.innerWidth >= 992) {
      const navLinkID = document.getElementById(`nav-item${this.index}`);
      const navButtonID = document.getElementById(
        `nav-item${this.index}__BV_toggle_`
      );

      navLinkID?.classList.remove("show");
      navButtonID?.setAttribute("aria-expanded", "false");
      navLinkID
        ?.getElementsByClassName("dropdown-menu")[0]
        .classList.remove("show");
    } else {
      return;
    }
  }
}
